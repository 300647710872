import removeHash from "./remove-hash";

/**
 * Regula los enlaces renderizados en contenido HTML
 *
 * @param {array} containers - Array de selectores CSS de los contenedores a monitorear
 * @returns {Function} - Función que toma una función de navegación
 */
export default (containers) => {
  // Variable para almacenar la función de navegación
  let navigateTo = null;

  // Manejador de eventos para los clics en enlaces
  const regulate = (e) => {
    // No es un enlace, o tiene target=_blank
    if (
      !e.target.matches(
        'a[href]:not([target="_blank"]), a[href]:not([target="_blank"]) *'
      )
    )
      return;

    // Enlace a una página .htm(l)
    if (e.target.href.indexOf(".htm") !== -1) return;

    // Buscar si el elemento está dentro de alguno de los contenedores especificados
    const container = containers.find(
      (container) => e.target.closest(container) !== null
    );
    // No está en los contenedores monitoreados
    if (!container) return;

    // Prevenir el comportamiento predeterminado
    e.preventDefault();

    // Si tenemos una función de navegación, la usamos
    if (navigateTo) {
      const href = removeHash(e.target.getAttribute("href"));
      navigateTo(href);
    }
  };

  /**
   * Función que toma una función de navegación para usar con los enlaces
   * @param {Function} navFunction - Función que toma un path y navega a él
   */
  return (navFunction) => {
    // Guardar la función de navegación
    navigateTo = navFunction;

    // Eliminar el event listener anterior para evitar duplicados
    document.removeEventListener("click", regulate, false);
    // Agregar el nuevo event listener
    document.addEventListener("click", regulate, false);
  };
};
