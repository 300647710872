import React, { Component } from "react";
import { fmt } from "../currentDate";
import moment from "moment";
import marked from "marked";
import cn from "classnames";
import hasClass from "has-class";
import _ from "lodash";
import LoadingIndicator from "../components/loading-indicator";
import { withRouter } from "react-router-dom";
import ScrollBars from "react-scrollbar";
import VerticalCenter from "../components/vertical-center";
import { resetMetadata } from "../actions/metas";
import { querySearch } from "../services/api";
import { getThumbnail } from "../services/vimeo";
import { getAlbumById, getTimesContrarianPages } from "../drawer-items";
import {
  PPV_MOVIE,
  BOOK,
  PLAYLIST,
  NEWS_ARTICLE,
  TRACK,
  TRACKS,
  ALBUM,
  TOUR,
} from "../util/contants/search_constants";
import timesContrarianImage from "../../images/search/times_contrarian_74０x740.jpg";
import playlistImage from "../../images/search/Performance_740x740.jpg";
import audioImage from "../../images/search/default_audio.jpg";
import SearchMenu from "../search-menu";
import SubCategoryResults from "./subcategories";
import SearchResults from "./results";
import Input from "../components/input";
import { connect } from "react-redux";
import {
  setSearch,
  resetSearch,
  updateSearch,
  changeSubCategory,
} from "../actions/search";
import { getBrioVideoInformation } from "../services/brio";

function getArtist(item) {
  switch (item.type) {
    case TRACK:
      return item.performer;
    case PPV_MOVIE:
      return item.director;
    case BOOK:
      return item.author;
    case PLAYLIST:
      return item.creator;
    case TOUR:
      return "";
    case ALBUM:
      //filter by id from state to get name
      if (item) {
        let parsedAlbums = getAlbumById(item.albumId);
        return (
          (parsedAlbums.artists || []).map((a) => a && a.name).join(" ") ||
          "Neil Young"
        );
      }
    case NEWS_ARTICLE:
      return item.byline;
    default:
      return "Neil Young";
  }
}

function desc(item, idx, onClick) {
  let artist = getArtist(item);
  let date, timelineDate;

  if (item.displayDate || item.releaseDate) {
    let { year, month, day } = item.releaseDate || item.displayDate;
    date = `${month}/${day}/${year}`;
    timelineDate = fmt(item.releaseDate);
  } else if (item.creationDate) {
    date = moment(item.creationDate).format("MM/DD/YYYY");
  }

  return (
    <div
      className="result"
      key={idx}
      data-type={item.type}
      data-id={item.id}
      data-page={item.pageNumber || false}
      data-tour={item.tourId || false}
      data-date={timelineDate || false}
      onClick={onClick}
    >
      <img className="image" src={item.searchImage} loading="lazy" />
      <div className="description">
        {item.type === NEWS_ARTICLE ? (
          <div
            className="title news-article"
            dangerouslySetInnerHTML={{ __html: marked(item.title || "") }}
          />
        ) : (
          <div className="title">{item.title}</div>
        )}
        <div className="information">
          {artist && <div className="artist">{artist},&nbsp;</div>}
          <div className="date">{date}</div>
        </div>
        {item.content && (
          <div
            className="excerpt"
            dangerouslySetInnerHTML={{ __html: marked(item.content || "") }}
          />
        )}
      </div>
      <br style={{ clear: "both" }} />
    </div>
  );
}

let lastSearch = "";

class SearchContainer extends Component {
  constructor(props) {
    super(props);
    this.ScrollBars = React.createRef();

    // Usar URLSearchParams para obtener parámetros de consulta
    const searchParams = new URLSearchParams(props.location.search);
    let q = searchParams.get("q");
    let category = searchParams.get("category");

    q = q || lastSearch;

    this.state = {
      results: {
        tracks: [],
        albums: [],
        films: [],
        newsArticle: {},
        performance: [],
        subCategories: {},
      },
      q,
      selectedCategory: category,
    };
    this.paginationInterval = null;
    this.queryTimeout = null;
    this.onChange = _.debounce(this.onChange.bind(this), 1000);
    this.onClick = this.onClick.bind(this);
    this.onBGClick = this.onBGClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.chooseCategory = this.chooseCategory.bind(this);
    this.checkCategoryType = this.checkCategoryType.bind(this);
    this.paginate = this.paginate.bind(this);
    this.clearResults = this.clearResults.bind(this);
    this.clearSearchTerm = this.clearSearchTerm.bind(this);
    this.setScrollingPosition = this.setScrollingPosition.bind(this);
    this.getScrollingPosition = this.getScrollingPosition.bind(this);
    this.setLoadingState = this.setLoadingState.bind(this);
  }

  componentDidMount() {
    let { q } = this.state;
    const { canPaginate, results, queryCategory } = this.props.searchData;
    const suggestTerm = localStorage.getItem("fuzzyTerm") || false;
    this.setState({ suggestTerm });
    //update url
    if (q && queryCategory && Object.keys(results).length > 0) {
      this.updateUrl({ search: q, selectedCategory: queryCategory });
      this.props.changeSubCategory(queryCategory);
      this.setState({ queryCategory, loadingPagination: false });
      //set position to scroll
      setTimeout(() => {
        const scrollBar = this.ScrollBars.current;
        const scrollingPosition = this.getScrollingPosition(queryCategory);
        if (scrollBar && scrollBar.state)
          scrollBar.scrollYTo(scrollingPosition);
      }, 500);
      //paginate
      if (canPaginate) {
        this.progressivePagination();
      }
    } else {
      //set new query
      this.setScrollingPosition();
      if (q) this.search(q, queryCategory);
    }
    resetMetadata();
  }

  componentWillUnmount() {
    clearInterval(this.paginationInterval);
    clearTimeout(this.queryTimeout);
  }

  clearSearchTerm(querysuggestTerm) {
    localStorage.removeItem("fuzzyTerm");
    this.setState({ querysuggestTerm });
  }

  setLoadingState() {
    this.setState({ loadingQuery: true });
    this.props.resetSearch();
  }

  onChange(string, querysuggestTerm = false) {
    const { queryCategory } = this.state;
    let search = string.trim();

    if (search == "") this.clearResults();

    this.setState({ q: search });

    //clear previous search
    this.clearSearchTerm(querysuggestTerm);

    this.search(search, queryCategory);
  }

  onClick(e) {
    const { q, suggestTerm } = this.state;
    const { termsArray } = this.props.searchData;
    let el = e.target;
    let id = el.getAttribute("data-id");
    let type = el.getAttribute("data-type");
    let page = el.getAttribute("data-page");
    let tour = el.getAttribute("data-tour");
    let timelineDate = el.getAttribute("data-date");
    let searchString =
      suggestTerm || termsArray ? `${suggestTerm} ${termsArray} ${q}` : q;

    let table = {
      //# && @ to replace ids on URIS
      track: "/info-card?track=#&search=true",
      album: "/album?id=#&tab=songs",
      film: "/film?id=#",
      ppvMovie: "/movietone/#",
      newsArticle: "/news/@/article?id=#",
      playlist: "/playlist?id=#",
      tour: "/performance-log/tour?media=setList&performanceid=#&tourid=@",
    };

    let template = table[type];

    if (type === "book" && timelineDate) {
      let location = `/timeline?${timelineDate}&album=${id}`;
      localStorage.setItem("searchedQuery", searchString);
      this.props.history.push(location);
      return;
    }

    if (!template) {
      console.error("don't know how to handle item of type:", type);
      return;
    }

    let url = template.replace("#", id);
    if (tour !== "false") {
      url = url.replace("@", tour);
    }

    if (page) {
      let pageNumber = `${page.replace(/\D/g, "")}`;
      //set on local storage prev route
      localStorage.setItem("searchedQuery", searchString);
      url = url.replace("@", pageNumber);
    }

    this.props.history.push(url);
  }

  checkCategoryType(category) {
    const categoryType = {
      tracks: "songs",
      ppvMovie: "movietone",
      newsArticle: "nyatc",
      tour: "performance",
    };

    const categoryString = categoryType[category];
    const choosenCategory = categoryString ? categoryString : `${category}s`;

    if (choosenCategory) {
      return `${choosenCategory}`;
    } else {
      return category;
    }
  }

  onBGClick(e) {
    let el = e.target;

    if (!(hasClass(el, "search-page") || hasClass(el, "search-page-inner")))
      return;

    e.preventDefault();
    e.stopPropagation();

    this.onClose();
  }

  onClose() {
    const { q } = this.state;
    this.props.history.goBack();
    this.setState({ q });
  }

  updateUrl(data) {
    const { search, selectedCategory } = data;
    const { pathname } = this.props.location;

    let searchParams = new URLSearchParams();
    if (search) searchParams.set("q", search);
    if (selectedCategory) searchParams.set("category", selectedCategory);

    this.setState({ queryCategory: selectedCategory });

    this.props.history.replace({
      pathname,
      search: searchParams.toString(),
    });
  }

  createSubCategory(data) {
    const {
      i,
      item,
      _category,
      _subCategory,
      categoryData,
      subCategories,
      stateResults,
    } = data;

    //create sub categories
    if (!stateResults[subCategories].hasOwnProperty(_category)) {
      stateResults[subCategories][_category] = {};
    }
    if (!stateResults[subCategories][_category].hasOwnProperty(_subCategory)) {
      stateResults[subCategories][_category][_subCategory] = [];
    }
    stateResults[subCategories][_category][_subCategory].push(item);

    //remove item from result
    if (_category == NEWS_ARTICLE) {
      categoryData[item.pageNumber] &&
        categoryData[item.pageNumber].splice(i, 1);
    } else {
      categoryData.splice(i, 1);
    }
  }

  async addSpecificProperties(data) {
    const { category, item, i } = data;
    const {
      vimeoId,
      orastreamVideoId,
      timelineImage,
      fullsizeAlbumArt,
      displayDate,
      _id,
      venue,
      startDate,
      playlistId,
      searchImage,
    } = item;
    if (category == PPV_MOVIE) {
      item.searchImage = orastreamVideoId
        ? ((await getBrioVideoInformation(orastreamVideoId)) || {}).cover
        : vimeoId && (await getThumbnail(vimeoId));
      item.id = orastreamVideoId ? orastreamVideoId : vimeoId;
    } else if (category === BOOK) {
      item.searchImage = timelineImage;
    } else if (category === PLAYLIST) {
      item.searchImage = timelineImage || fullsizeAlbumArt;
      item.id = playlistId;
      item.releaseDate = displayDate;
    } else if (category === TOUR) {
      item.type = TOUR;
      item.searchImage = playlistImage;
      item.id = _id;
      item.title = venue;
      item.releaseDate = startDate;
    } else if (category === ALBUM) {
      item.searchImage =
        !searchImage || searchImage.includes("undefined")
          ? audioImage
          : item.searchImage;
    } else if (category === TRACKS) {
      const storedTrack = this.props.contentfulTracks[item._id];
      if (storedTrack.presentOn) {
        let albums = _.clone(storedTrack.presentOn);
        albums = Object.keys(albums).reduce(
          (acc, e) => [...acc, albums[e]],
          []
        );
        albums = albums.filter((a) => a.isAlbum);
        albums = albums.sort(
          (a, b) =>
            moment(b.releaseDateTimeStamp) - moment(a.releaseDateTimeStamp)
        );
        if (albums.length > 0) {
          const albumTrack = albums[albums.length - 1];
          item.searchImage = albumTrack.infoCardImage;
        }
      }
      item.searchImage =
        !item.searchImage || item.searchImage.includes("undefined")
          ? audioImage
          : item.searchImage;
    }
  }

  hasSubCategory(data) {
    const { subCategory, item, categoryData, _category, i, stateResults } =
      data;

    const subCategories = "subCategories";

    if (subCategory) {
      //create items by subcategory
      subCategory.forEach((_subCategory) => {
        //Parse newsArticle
        if (_category === NEWS_ARTICLE) {
          const pageInfo = getTimesContrarianPages(_subCategory);

          let subCategoryName =
            pageInfo.columnsTitles && pageInfo.columnsTitles[0].title;

          for (let i = 0; i < item.length; i++) {
            let article = item[i];
            const {
              column = false,
              image1,
              image2,
              headlineText,
              date,
              excerpt,
              id,
              bodyText,
            } = article;
            let urlInstance =
              bodyText.lastIndexOf("//images") && bodyText.indexOf(".jpg");
            let image =
              (urlInstance > -1 &&
                bodyText &&
                bodyText.substring(
                  bodyText.lastIndexOf("//images") + 1,
                  bodyText.indexOf(".jpg")
                )) ||
              false;
            //Add properties to articles
            article.searchImage =
              image !== "undefined" && image.length < 150
                ? `/${image}.jpg`
                : image1 || image2 || timesContrarianImage;
            article.creationDate = date;
            article.id = id;
            article.type = _category;
            article.title = headlineText;
            article.content = excerpt;

            if (column && pageInfo.hasOwnProperty(`${column}Headline`)) {
              let headline = pageInfo[`${column}Headline`];
              subCategoryName = !headline.includes("image")
                ? headline
                : subCategoryName;
            }

            this.createSubCategory({
              i,
              item: article,
              _category,
              _subCategory: subCategoryName,
              categoryData,
              subCategories,
              firstLevelCategory: _subCategory,
              stateResults,
            });
          }
        } else {
          const hasSubCategory =
            item.hasOwnProperty(_subCategory) &&
            (item[_subCategory] === true || item[_subCategory]);

          if (hasSubCategory) {
            this.createSubCategory({
              i,
              item,
              _category,
              _subCategory,
              categoryData,
              subCategories,
              stateResults,
            });
          }
        }
      });
    }
    return stateResults;
  }

  canPaginate(data) {
    const { foundCategories, _category, totalLength, existingCategories } =
      data;
    let { canPaginate } = data;
    if (totalLength < foundCategories[_category].totalResults) {
      //Pagination is done by 20
      foundCategories[_category].paginationStart += 20;
      foundCategories[_category].pagination = true;
      canPaginate.push("true");

      //stop pagination for last index

      if (existingCategories.length === 1) {
        const { paginationStart, totalResults } = foundCategories[_category];
        if (paginationStart > totalResults) {
          canPaginate.push("false");
        }
      }
    } else {
      canPaginate.push("false");
    }
  }

  async parseQueryResponse(data) {
    const { subCategory, categoryData, _category, stateResults } = data;
    const isArray = _.isArray(categoryData);

    const categoryInformation = isArray
      ? _.cloneDeep(categoryData)
      : subCategory;
    let page;
    for (let i = 0; i < categoryInformation.length; i++) {
      let item = (page = categoryInformation[i]);

      if (!isArray) {
        item = categoryData[item];
      }

      //Create properties according types
      await this.addSpecificProperties({
        categoryData,
        category: _category,
        item,
        i,
      });

      //Create subcategories
      this.hasSubCategory({
        subCategory,
        item,
        categoryData,
        _category,
        i,
        stateResults,
      });

      //add items to especific properties
      if (!isArray && _.isString(page)) {
        if (stateResults[_category].hasOwnProperty(page)) {
          stateResults[_category][page].push(item);
        }
      } else if (isArray && item && !item.outTake) {
        stateResults[_category].push(item);
      }
    }
    return stateResults;
  }

  categorizeResults(data) {
    const {
      foundCategories,
      results,
      queryCategory,
      existingCategories,
      loadingPagination,
      termsArray,
    } = data;

    let totalLength = 0;
    let canPaginate = [];
    let resultMetrics = {};

    const stateResults = _.cloneDeep(this.props.searchData.results);
    if (!stateResults.hasOwnProperty("subCategories"))
      stateResults["subCategories"] = {};
    existingCategories.forEach((_category) => {
      //here create category
      let categoryData = results[_category];
      if (
        !loadingPagination &&
        !resultMetrics.hasOwnProperty(_category) &&
        foundCategories[_category]
      ) {
        resultMetrics[_category] = {};
        resultMetrics[_category]["totalResults"] =
          foundCategories[_category].totalResults;
      }

      //create category if it doesn't exist
      if (!stateResults.hasOwnProperty(_category)) {
        stateResults[_category] = [];
      }

      const itemData = {
        categoryData,
        results,
        _category,
        foundCategories,
        stateResults,
      };
      if (_category === TRACKS) {
        itemData.subCategory = ["outTake"];
        this.parseQueryResponse(itemData);
      } else if (_category === NEWS_ARTICLE) {
        itemData.subCategory = Object.keys(categoryData);
        totalLength = itemData.subCategory.reduce(function (total, key) {
          return (total +=
            (stateResults[_category].hasOwnProperty(key) &&
              stateResults[_category][key].length) ||
            (results[_category] && results[_category][key].length));
        }, 0);
        //set performances on category only
        this.parseQueryResponse(itemData);
      } else if (_category === TOUR) {
        //return only performances
        itemData.categoryData = itemData.results[_category] = [].concat(
          ...categoryData.map((_tour) => _tour.performances)
        );
        this.parseQueryResponse(itemData);
      } else {
        this.parseQueryResponse(itemData);
      }
      //Add already paginated items to new results
      if (
        _.isArray(stateResults[_category]) &&
        stateResults[_category] &&
        stateResults[_category].length > 0
      ) {
        totalLength = stateResults[_category].length;
      }
      //add next pagination index
      this.canPaginate({
        foundCategories,
        _category,
        totalLength,
        existingCategories,
        canPaginate,
      });
    });
    const { subCategories } = stateResults;
    const subCategoryData = subCategories[queryCategory];
    canPaginate = canPaginate.includes("true");

    const parsedResults = {
      foundCategories,
      results: stateResults,
      subCategoryData,
      canPaginate,
      existingCategories,
      resultMetrics,
      queryCategory,
      termsArray,
    };
    const state = {
      canPaginate,
      loadingQuery: false,
    };

    if (!loadingPagination) {
      state.existingCategories = existingCategories;
    }

    state.loadingPagination = foundCategories.hasOwnProperty(queryCategory)
      ? foundCategories[queryCategory].pagination
      : false;

    this.setState(state);
    return parsedResults;
  }

  getExistingCategories(foundCategories) {
    return Object.keys(foundCategories).filter(
      (_category) => _category !== "overallTotalResults"
    );
  }

  progressivePagination() {
    this.paginationInterval = setInterval(() => {
      const { canPaginate } = this.props.searchData;

      if (canPaginate) {
        this.paginate();
      } else {
        clearInterval(this.paginationInterval);
      }
    }, 3000);
  }

  async paginate() {
    const { q, queryCategory } = this.state;
    const { termsArray } = this.props.searchData;
    const { foundCategories: pagination, canPaginate } = this.props.searchData;
    if (!canPaginate) {
      clearInterval(this.paginationInterval);
      return;
    }
    const queryString = this.parseString(q);
    const { query, suggestTerm } = queryString;
    const queryResult = await querySearch({ pagination, query, suggestTerm });
    if (queryResult) {
      const { results = [], foundCategories = [] } = queryResult;
      const existingCategories = this.getExistingCategories(foundCategories);
      //parsing function
      const dataResult = this.categorizeResults({
        foundCategories,
        results,
        queryCategory,
        loadingPagination: true,
        existingCategories,
        termsArray,
      });
      this.props.updateSearch(dataResult);
    }
  }

  parseString(search) {
    // Add backslash to escape regex special characters
    const escapeString = (str) =>
      str.replace(/[\'\"\"]/g, "'").replace(/[^\s\w\d]/g, "\\$&");
    let suggestTerm = false;
    let query = search;
    const strings = search.split(" ");

    if (search) {
      // Query types implemented
      //1// Detect exact query match
      // Matching first and last double quote char on search string with regex
      const exactMatch = search.match(/^("|\")(.*?)("|\")$/);
      //2// Detect prefix query match
      // search must a be string of length between 2 and 4
      const prefixMatch = search.match(/^[a-zA-Z]{2,4}$/);
      if (exactMatch) {
        // Remove quotations from exact match
        query = escapeString(query.substring(1, query.length - 1));
      }
      if (prefixMatch) {
        query = `${search}\*`;
      } else {
        // Prepare a fuzzy search
        //only the first word of the string
        query = strings
          .map((_word, index) => {
            _word = escapeString(_word);
            if (
              index === 0 &&
              strings.length > 0 &&
              strings.length < 3 &&
              _word.length > 3
            ) {
              suggestTerm = _word;
            }
            //add %% to each word except to characters less than 4
            if (_word.length > 3) {
              _word = `%${_word}%`;
            }
            return _word;
          })
          .join(" ");
      }
      return { query, suggestTerm };
    }
  }

  clearResults() {
    clearInterval(this.paginationInterval);

    lastSearch = "";

    this.props.resetSearch();
    this.setScrollingPosition(0);
    this.setState({
      q: "",
      suggestTerm: false,
      loadingQuery: false,
      queryCategory: "",
      scrollingPosition: 0,
    });

    this.props.history.replace("/search");
  }

  search(search, selectedCategory) {
    let termsArray = false;
    //clear pagination interval
    clearInterval(this.paginationInterval);
    //parse query
    const queryString = this.parseString(search);

    //reset search and scroll position
    this.props.resetSearch();
    this.setState(
      {
        results: {},
        canPaginate: false,
        loadingQuery: true,
        scrollingPosition: 0,
        suggestTerm: false,
      },
      async () => {
        const queryResult = await querySearch(queryString);
        if (queryResult) {
          const {
            results = [],
            foundCategories = [],
            suggestTerm,
          } = queryResult;
          console.log(
            "%c ############## Suggested Terms ###############",
            "color:red; background:black;",
            suggestTerm
          );
          if (suggestTerm) {
            let fuzzyTerm = suggestTerm ? suggestTerm[0] : false;
            let score = fuzzyTerm ? fuzzyTerm.score : 0;
            const similarity = score.toFixed(2) * 100;
            termsArray = (
              suggestTerm.map((_term) => _term.term) || []
            ).toString();
            termsArray = termsArray.split(",").join(" ");
            if ((similarity > 20 && similarity < 90) || similarity >= 100) {
              fuzzyTerm = fuzzyTerm.term;
              localStorage.setItem("fuzzyTerm", fuzzyTerm);
              this.setState({ suggestTerm: fuzzyTerm });
            }
          }

          const existingCategories =
            this.getExistingCategories(foundCategories);

          let queryCategory = existingCategories.find(
            (_category) => _category === selectedCategory
          );
          //keep current category selected
          if (!queryCategory) queryCategory = existingCategories[0];

          this.updateUrl({ search, selectedCategory: queryCategory });
          //parsing function
          let parseData = this.categorizeResults({
            foundCategories,
            results,
            queryCategory,
            existingCategories,
            termsArray,
          });

          this.props.setSearch(parseData);
          //progressive pagination
          this.progressivePagination();
        }
      }
    );

    lastSearch = search;
  }

  chooseCategory(category) {
    let { q, queryCategory } = this.state;
    const { results, foundCategories } = this.props.searchData;
    if (category !== queryCategory) {
      //Change subCategoryData on redux
      this.props.changeSubCategory(category);
      const loadingPagination = foundCategories.hasOwnProperty(category)
        ? foundCategories[category].pagination
        : false;
      this.setState(
        {
          loadingQuery: true,
          queryCategory: category,
          loadingPagination,
          scrollingPosition: 0,
        },
        () => {
          setTimeout(() => {
            this.updateUrl({ search: q, selectedCategory: category });
            this.setState({ loadingQuery: false });
          }, 2000);
        }
      );
    }
  }

  getScrollingPosition(queryCategory) {
    const storage = JSON.parse(localStorage.getItem("searchScrollingPosition"));
    if (storage.hasOwnProperty(queryCategory)) {
      return parseInt(storage[queryCategory]);
    } else {
      return 0;
    }
  }

  setScrollingPosition(topPosition, queryCategory) {
    //set scrolling Position on localStorage
    const storage =
      JSON.parse(localStorage.getItem("searchScrollingPosition")) || {};
    let newStorage = storage;
    if (
      queryCategory !== undefined &&
      topPosition !== undefined &&
      topPosition > 0
    ) {
      newStorage = { ...storage, [queryCategory]: topPosition };
    }
    localStorage.setItem("searchScrollingPosition", JSON.stringify(newStorage));
  }

  render() {
    // Usar URLSearchParams para los parámetros de consulta
    const searchParams = new URLSearchParams(this.props.location.search);
    let fromMenu = searchParams.get("fromMenu");
    let cx = cn("search-page", { "from-menu": fromMenu });

    const {
      queryCategory,
      q = false,
      querysuggestTerm = false,
      loadingPagination,
      loadingQuery,
      suggestTerm,
    } = this.state;
    const { results, existingCategories, subCategoryData, resultMetrics } =
      this.props.searchData;
    const resultsLength =
      existingCategories && existingCategories.length > 0 ? true : false;
    let queryText =
      resultsLength && q
        ? `Search results for ${q}`
        : q && !loadingQuery && !resultsLength
        ? `No results found for ${q}`
        : "";

    if (suggestTerm) {
      if (resultsLength)
        queryText = (
          <span>
            Did you mean <a>{suggestTerm}</a> ?
          </span>
        );
    }

    return (
      <div className="search-page-wrapper">
        <VerticalCenter
          className={cx}
          innerClassName="search-page-inner"
          onClick={this.onBGClick}
        >
          <div className="content">
            <div className="out-close-button" onClick={this.onClose} />
            <div className="background" />

            <Input
              className="searchbox"
              value={q}
              autofocus={true}
              focusOnClear={true}
              topShadow={true}
              placeholder="Search"
              onChange={this.onChange}
              close={this.onClose}
              clearResults={this.clearResults}
              suggestTerm={suggestTerm}
              querysuggestTerm={querysuggestTerm}
              clearSearchTerm={this.clearSearchTerm}
              setLoadingState={this.setLoadingState}
            />

            <div className="results-area">
              <div
                className="results-for"
                onClick={() => {
                  if (suggestTerm) {
                    //make search again
                    localStorage.removeItem("fuzzyTerm");
                    this.onChange(suggestTerm, true);
                  }
                }}
              >
                {queryText}
              </div>
              {resultsLength && (
                <SearchMenu
                  categories={existingCategories}
                  queryCategory={queryCategory}
                  chooseCategory={this.chooseCategory}
                  categoryType={this.checkCategoryType}
                />
              )}
              {loadingQuery ? (
                <center>
                  <LoadingIndicator />
                </center>
              ) : (
                <div className="results-wrapper">
                  <ScrollBars
                    ref={(ref) => (this.ScrollBars.current = ref)}
                    onScroll={(value) => {
                      this.setScrollingPosition(
                        value.topPosition,
                        queryCategory
                      );
                    }}
                  >
                    {/* Regular results  */}
                    <SearchResults
                      results={results}
                      queryCategory={queryCategory}
                      onClick={this.onClick}
                      categoryType={this.checkCategoryType}
                      desc={desc}
                      loadingPagination={loadingPagination}
                      resultMetrics={resultMetrics}
                      subCategoryData={subCategoryData}
                    />

                    {/* Categorize results */}
                    {subCategoryData && (
                      <SubCategoryResults
                        queryCategory={queryCategory}
                        subCategoryData={subCategoryData}
                        onClick={this.onClick}
                        desc={desc}
                      />
                    )}
                  </ScrollBars>
                </div>
              )}
            </div>
            <div className="bottom-line" />
          </div>
        </VerticalCenter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchData: state.search,
  contentfulTracks: state.tracks.byContentfulId,
});

export default withRouter(
  connect(mapStateToProps, {
    setSearch,
    updateSearch,
    resetSearch,
    changeSubCategory,
  })(SearchContainer)
);
