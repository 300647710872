import { compact } from "lodash";
import removeHash from "./remove-hash";

// LEGACY SITE OLD URL PATTERM
// domain/#/[QUERY]
const legacy = ({ hash }) => removeHash(hash);

// STATIC SITE URL PATTERN
// *domain/news/[PAGE_ID]
// domain/news/[PAGE_ID]/article/[ARTICLE_SLUG]
// domain/about
// domain/terms
// domain/privacy
// *domain/credits
// *domain/contact
// domain/contact/subject
// domain/account
// domain/account/overview/[*]
// domain/account/subscription/[*]
// domain/account/plans
// domain/account/receipts
// domain/account/notifications
// domain/account/presale
//
// (*) ignored
const prerender = ({ pathname, search }) => {
  const params = compact(pathname.split("/"));
  // Extraer query params de search
  const query = {};
  if (search) {
    const searchParams = new URLSearchParams(search);
    searchParams.forEach((value, key) => {
      query[key] = value;
    });
  }

  // Home page [no pound sign]
  if (params.length === 0) return false;

  const display = params.shift();
  const view = params.shift();
  switch (display) {
    case "news":
      // Category
      if (params.shift() === undefined) {
        const newPathname = `/${display}/${view || "1"}`;
        return pathname !== newPathname ? newPathname : false;

        // Single
      } else if (!query.id) {
        const article = params.shift();
        return article ? `/${display}/${view}/article?id=${article}` : false;
      }

      return false;

    case "account":
      const screen = view === "presale" ? "tickets" : view;
      return screen ? `/${display}?screen=${screen}` : false;

    case "about":
      return "/audio-setup";

    case "contact":
      return view ? `/${display}` : false;

    case "terms":
    case "privacy":
      window.location.href = `/${display}.html`;
      return false;

    default:
      return false;
  }
};

export default ({ history, location }) => {
  try {
    const translated =
      location.hash !== "" ? legacy(location) : prerender(location);
    // console.log('%cTranslated location:', 'color:deeppink', { org: location, translated });

    // Usar history.push directamente en lugar de router.createLocation
    if (translated) {
      history.push(translated);
      return true;
    }
    return false;
  } catch (error) {
    console.warn(error);
    return false;
  }
};
