//import config from '../config.json';

/* Google tracking -----------------------*/
(function (i, s, o, g, r, a, m) {
  i["GoogleAnalyticsObject"] = r;
  (i[r] =
    i[r] ||
    function () {
      (i[r].q = i[r].q || []).push(arguments);
    }),
    (i[r].l = 1 * new Date());
  (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m);
})(window, document, "script", "//www.google-analytics.com/analytics.js", "ga");

var _gaq = _gaq || [];
ga("create", process.env.GOOGLE_TRACKING_ID, "auto");
/*----------------------------------------*/

/* Google Tag Manager -----------------------*/
(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", process.env.GOOGLE_TAG_MANAGER_ID);
/*----------------------------------------*/

export function pageView(location) {
  console.log("PAGE VIEW: ", location);
  ga("send", {
    hitType: "pageview",
    page: location,
  });
}

export function amazonClick(href) {
  ga("send", "event", {
    eventCategory: "Amazon Link",
    eventAction: "click",
    eventLabel: href,
    transport: "beacon",
  });
}

export function registerButtonClick({ value, currency }) {
  // Google
  ga("send", "event", "Register Button Click", "Click", "Register");
}
